/* eslint-disable no-restricted-syntax */
import { handleResponse } from '../../../httpClient/handleResponse';
import { httpRequest } from '../../../httpClient/httpRequest';
import { QueryObject } from '../../../httpClient/httpRequest/types';
import { FileType, FileStatic } from '../types';
import { isObject } from '../../../utils/helpers';

/**
 * When successful server responds with new file
 * @param file File
 */
export const apiUploadFile = (file: File, query: QueryObject): Promise<FileType> => {
  // add file to formData
  const formData = new FormData();
  formData.append('uploadedFile', file);

  return httpRequest
    .postFormData('/core/file', {
      body: formData,
      query,
    })
    .then((res) => handleResponse<FileType>(res))
    .then((data) => data)
    .catch();
};

/**
 * When successful server responds with new file
 * @param file File
 */
export const apiReplaceFile = (fileId: string, file: File, query: QueryObject): Promise<FileType> => {
  // add file to formData
  const formData = new FormData();
  formData.append('uploadedFile', file);

  return httpRequest
    .putFormData(`/core/file/${fileId}`, {
      body: formData,
      query,
    })
    .then((res) => handleResponse<FileType>(res))
    .then((data) => data)
    .catch();
};

export const apiDeleteFile = (_id: string, category?: 'logo'): Promise<unknown> =>
  httpRequest.delete(`/core/file/${_id}`, category ? { query: { category } } : undefined).then(handleResponse);

export const apiFindFiles = (filter?: QueryObject): Promise<FileType[] | FileStatic[]> =>
  httpRequest
    .get(`/core/file`, { query: filter || {} })
    .then((res) => handleResponse<FileType[] | FileStatic[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();
