import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SelectFieldProps } from './types';
import { useInputFieldStyles } from '../InputField/styles';

const useStyles = makeStyles(() =>
  createStyles({
    selectOption: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontWeight: 400,
      '&:hover:before, &.Mui-focusVisible:before': {
        content: 'none',
      },
    },
  }),
);

export const SelectField = ({
  name,
  label,
  options = [],
  disabled,
  required,
  onBlur,
  onFocus,
}: SelectFieldProps): ReactElement => {
  const classes = useStyles();
  const inputFieldClasses = useInputFieldStyles();
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps): ReactElement => {
        return (
          <TextField
            id={name}
            name={name}
            disabled={disabled}
            className={inputFieldClasses.root}
            select
            error={!!(meta.touched && meta.error)}
            helperText={(meta.touched && meta.error) || ''}
            label={label}
            onChange={field.onChange}
            value={field.value || typeof field.value === 'number' ? field.value : ''}
            required={required}
            fullWidth
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }

              field.onBlur(e);
            }}
            onFocus={onFocus || undefined}
          >
            {options.map((option) => (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <MenuItem className={classes.selectOption} key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    </Field>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ).isRequired,
};

SelectField.defaultProps = {
  required: false,
};
