import React, { ReactElement, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CheckCircleRounded, HighlightOffRounded } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { EvaluationResult } from '../../../../lib/context/instructionViewer/types';
import { EvaluationScreenProps } from './types';
import { DATE_FORMAT } from '../../../../../lib/constants/dateFormat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrap: {
      '& .MuiSvgIcon-root': {
        fontSize: '10rem',
        color: ({ evaluated }: { evaluated: EvaluationResult }): string =>
          evaluated === 'passed' ? theme.palette.success.main : theme.palette.error.main,
      },
    },
    textWrap: {
      '& p': {
        fontWeight: 600,
      },
    },
  }),
);

export const EvaluationScreen = (props: EvaluationScreenProps): ReactElement => {
  const { evaluated, nextDue, shouldAutoReset } = props;
  const { t } = useTranslation();
  const classes = useStyles({ evaluated });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timer: any = null;

  useEffect(() => {
    if (evaluated && shouldAutoReset) {
      timer = setTimeout(() => {
        window.location.reload();
      }, 30000);
    }
    return () => clearTimeout(timer);
  }, [evaluated]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <Box className={classes.iconWrap}>
        {evaluated === 'passed' ? <CheckCircleRounded /> : <HighlightOffRounded />}
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" className={classes.textWrap} px="12.5%">
        {evaluated === 'passed' ? (
          <>
            <Box mb={3}>
              <Typography align="center" variant="h5">
                {t('pages.myInstructionView.evaluationScreen.successHeading')}
              </Typography>
            </Box>
            <Box>
              {nextDue ? (
                <Typography align="center" variant="body1">
                  {t('pages.myInstructionView.evaluationScreen.successContent')} {dayjs(nextDue).format(DATE_FORMAT)}{' '}
                  {t('pages.myInstructionView.evaluationScreen.valid')}
                </Typography>
              ) : (
                <Typography align="center" variant="body1">
                  {t('pages.myInstructionView.evaluationScreen.successContentOneTime')}
                </Typography>
              )}
            </Box>
          </>
        ) : (
          <Box>
            <Typography align="center" variant="body1">
              {t('pages.myInstructionView.evaluationScreen.failureContent')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
