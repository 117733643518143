import React, { useEffect, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccessTemplate } from '../../components/templates';
import { config } from '../../lib/config';
import { attemptLoginAccount, LoginFormValueType } from '../../lib/store/account';
import { LoginForm } from '../../components/organisms/form/LoginForm';
import { NonAuthRoutes } from '../../lib/constants/pagePaths';
import { attemptVerifyAccount } from '../../lib/store/account/effects';
import { LogoHeadingBox } from '../../components/molecules/box/LogoHeadingBox';
import { InfoBar } from '../../components/molecules/box/InfoBar';
import { RouterLink } from '../../components/molecules/link/RouterLink';
import { successAccount } from '../../lib/store/account/actions';

const useStyles = makeStyles({
  tocText: {
    textAlign: 'center',
    zIndex: 1,
  },
});

const Login: React.FC = (): ReactElement => {
  const { t } = useTranslation(['init']);
  const location = useLocation();
  const classes = useStyles();

  const { vtoken } = queryString.parse(location.search);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(successAccount(''));
  }, []);

  useEffect(() => {
    const verifyUser = (token: string): void => {
      reduxDispatch(attemptVerifyAccount(token));
    };
    if (vtoken && typeof vtoken === 'string') {
      verifyUser(vtoken);
    }
  }, [reduxDispatch, vtoken]);

  const handleSubmit = (formState: LoginFormValueType): void => {
    reduxDispatch(attemptLoginAccount(formState));
  };

  return (
    <AccessTemplate
      infoBar={<InfoBar infoLink={config.wikiUrl || ''} />}
      header={<LogoHeadingBox title={t('init:pages.login.header.title')} />}
      toc={(): ReactElement => {
        return (
          <Typography className={classes.tocText} color="textSecondary" variant="body2">
            <Trans
              i18nKey="init:pages.login.content.toc"
              components={{
                toc: <Link color="textSecondary" target="_blank" href={config.toc} />,
                privacyPolicy: <Link color="textSecondary" target="_blank" href={config.privacyPolicy} />,
                legalNotice: <Link color="textSecondary" target="_blank" href={config.legalNotice} />,
              }}
            />
          </Typography>
        );
      }}
      footer={(): ReactElement => {
        return (
          <Typography align="center">
            <Trans
              i18nKey="init:shared.pages.footer.registerText"
              components={{
                register: <Link component={RouterLink} color="secondary" to={NonAuthRoutes.register} />,
              }}
            />
          </Typography>
        );
      }}
    >
      <LoginForm handleSubmit={handleSubmit} />
    </AccessTemplate>
  );
};

export default Login;
