import { devEnvironment } from './development';
import { sandboxEnvironment } from './sandbox';
import { productionEnvironment } from './production';

const env = process.env.REACT_APP_NODE_ENV;
const nodeEnv = process.env.NODE_ENV;

const defaults = {
  website: 'https://d-simple.com',
  toc: 'https://www.d-simple.com/agb',
  legalNotice: 'https://www.d-simple.com/legal-notice',
  privacyPolicy: 'https://www.d-simple.com/privacy-policy',
  wikiUrl: 'https://wiki.d-simple.com',
  spaTestUrl: 'http://localhost',
  spacesCdn: 'https://space.d-simple-storage.com',
  sentry: 'https://e92585bf51924be3b61b04859d0b9cb4@o4504106395828224.ingest.sentry.io/4504107389943808',
  // TODO: change access of API key
  wikiAPIKey:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGkiOjE0LCJncnAiOjEsImlhdCI6MTcyNTQ0MTQ4NCwiZXhwIjoxODIwMTE0Mjg0LCJhdWQiOiJ1cm46d2lraS5qcyIsImlzcyI6InVybjp3aWtpLmpzIn0.GB08eHdXAkqViSfpPGPcv1WzAIuy1mU6FDbllc_I80cFyW1Oc7syipLTaUOd1SfXz7LgMb8NRjOh3eHI0DXJV8lwSjQrNOIw8nz6rCCmp4HglWfq1_AD8GowQ4DtEwKdm84QmIBXVqMjd4-Zn2nWi0BW3l7yIEa-dOIRMqAZrCWtHc_lQf7E6PrrQHgAX6S6KxOqyenFX9TG4RJ8qAKHnqDsCYj0IHjxDtiy_N8_AFFoTRSH0dHqEG-KmH5hK1NAjM6XCZfPfqav7cRTzai5y5_M9KeaCj-SeUPNDE5Nn2seV_XG4UDfs7pooaleSEDfwehgq06L__phZmKPtQwGHg',
};

export const config = {
  ...defaults,
  ...(env === 'sandbox' ? sandboxEnvironment : nodeEnv === 'development' ? devEnvironment : productionEnvironment),
};
