import React, { ReactElement, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { SearchRounded, ImageRounded } from '@material-ui/icons';

import { Box, Grid, TextField, MenuItem } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { attemptGetFiles } from '../../lib/store/file/effects';
import { RootState } from '../../lib/store';

import { SingleFieldForm } from '../../components/organisms/form/SingleFieldForm';

import { TextWithIconButton } from '../../components/molecules/button';
import { FileCard } from './components/FileCard';

import { PlaceholderBox } from '../../components/molecules/box/PlaceholderBox';
import { FileUploadCard } from './components/FileUploadCard';
import { setFileUploadList } from '../../lib/store/file/actions';
import { isFileLoadingState, FileType, FileStatic } from '../../lib/store/file/types';

import { FileStaticCard } from './components/FileStaticCard';
import { isFileType } from '../../lib/utils/typeguards';
import { CategoryPicker } from '../../components/organisms/menu/CategoryPicker';
import { hasAccountRequiredPermission } from '../../lib/utils';
import { AvailablePermissions } from '../../lib/constants/availablePermissions';
import {
  attemptGetFileCategories,
  attemptCreateFileCategory,
  attemptUpdateFileCategory,
  attemptDeleteFileCategory,
} from '../../lib/store/fileCategory/effects';

export type FileLibraryProps = {
  handleSelect?: (file: FileType | FileStatic) => void;
  initialGroup?: { value: string; isCustomCategory: boolean };
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  outline: 'none',
  transition: 'border-color .24s ease-in-out',
  position: 'absolute' as const,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#111',
  backgroundColor: '#fcfcfc',
  color: '#bdbdbd',
  zIndex: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
};

const rejectStyle = {
  borderColor: '#ff1744',
};
const useStyles = makeStyles(() =>
  createStyles({
    selectOption: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontWeight: 400,
      '&:hover:before, &.Mui-focusVisible:before': {
        content: 'none',
      },
    },
  }),
);

export const FileLibrary = (props: FileLibraryProps): ReactElement => {
  const { handleSelect, initialGroup } = props;
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const {
    file: { fileList, loading },
    fileCategory: { fileCategories, loading: fileCategoriesLoading },
    organisation: { current: currentOrganisation },
    account: { current: currentAccount },
  } = useSelector((state: RootState) => state);

  const accountPermissions = (currentAccount && currentAccount.role.permissions) || [];

  const hasFileCreatePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFilesCreate,
  ]);
  const hasFileDeletePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFilesDelete,
  ]);

  const hasFileCategoryCreatePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFileCategoriesCreate,
  ]);

  const hasFileCategoryDeletePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFileCategoriesDelete,
  ]);

  const hasFileCategoryUpdatePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFileCategoriesUpdate,
  ]);

  const showExamples = currentOrganisation?._id === '62265aed27bac500134848f4';

  const [selectedGroup, setSelectedGroup] = React.useState<{ value: string; isCustomCategory: boolean }>(
    initialGroup || { value: '', isCustomCategory: false },
  );

  const initialSortBy = localStorage.getItem('sort-file-library') || 'createdAt';
  const [sortBy, setSortBy] = React.useState(
    ['originalName', 'createdBy'].includes(initialSortBy) ? initialSortBy : 'createdAt',
  );

  const [currentSearchTerm, setCurrentSearchTerm] = React.useState('');

  const [editMode, setEditMode] = React.useState('');

  const isLoading = loading === 'fileGet' || fileCategoriesLoading === 'fileCategoryGet';

  const handleCreateCategory = (value: string) => {
    reduxDispatch(attemptCreateFileCategory(value));
  };

  const handleUpdateCategory = (_id: string, name: string, oldName: string) => {
    if (_id) {
      reduxDispatch(attemptUpdateFileCategory({ _id, name }, oldName));
    }
  };

  const handleDeleteCategory = (_id: string, name: string) => {
    reduxDispatch(attemptDeleteFileCategory(_id, name));
    if (name === selectedGroup.value) {
      setSelectedGroup({ value: '', isCustomCategory: false });
    }
  };

  useEffect(() => {
    reduxDispatch(
      attemptGetFiles({
        sort: sortBy === 'originalName' ? 'asc' : 'desc',
        category: !selectedGroup?.value || selectedGroup?.isCustomCategory ? 'images' : 'static',
        group: selectedGroup?.value || undefined,
        originalName: currentSearchTerm,
        sortBy,
      }),
    );
  }, [selectedGroup, sortBy, currentSearchTerm]);

  useEffect(() => {
    const currentValue = localStorage.getItem('sort-file-library');
    if (currentValue !== sortBy) {
      localStorage.setItem('sort-file-library', sortBy);
    }
  }, [sortBy]);

  useEffect(() => {
    reduxDispatch(attemptGetFileCategories());
  }, []);

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    reduxDispatch(setFileUploadList(acceptedFiles.map((file) => ({ file, tempId: uuidv4() }))));
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
    onDrop,
    noClick: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const handleSearch = (value: string) => {
    setCurrentSearchTerm(value);
  };

  const handleSelectGroup = (value: string, isCustomCategory: boolean) => {
    setSelectedGroup({ value, isCustomCategory });
  };

  return (
    <>
      {(!selectedGroup?.value || selectedGroup?.isCustomCategory) && hasFileCreatePermission && (
        <section className="container">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div {...getRootProps({ style, className: 'dropzone' })}>
            {isDragAccept && <p>{t('module-core:shared.common.dragNDrop')}</p>}
          </div>
        </section>
      )}

      <Grid container spacing={2}>
        <Grid item xs={3} lg={2}>
          <CategoryPicker
            setEditMode={setEditMode}
            isLoading={
              fileCategoriesLoading === 'fileCategoryAdd'
                ? 'isCreating'
                : fileCategoriesLoading === 'fileCategoryEdit'
                ? 'isEditing'
                : fileCategoriesLoading === 'fileCategoryDelete'
                ? 'isDeleting'
                : undefined
            }
            handleCreateCategory={hasFileCategoryCreatePermission ? handleCreateCategory : undefined}
            handleUpdateCategory={hasFileCategoryUpdatePermission ? handleUpdateCategory : undefined}
            handleDeleteCategory={hasFileCategoryDeletePermission ? handleDeleteCategory : undefined}
            categories={[
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.office'),
                value: 'signs/office-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.gastronomy'),
                value: 'signs/gastronomy-signs',
              },

              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.craft'),
                value: 'signs/craft-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.hygiene'),
                value: 'signs/hygiene-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.transport'),
                value: 'signs/transport-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.other'),
                value: 'signs/other-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.caricatures'),
                value: 'signs/caricatures-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.imagesSigns'),
                value: 'signs/images-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.commandmentSigns'),
                value: 'signs/commandment-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.fireProtectionSigns'),
                value: 'signs/fire-protection-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.prohibitionSigns'),
                value: 'signs/prohibition-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.rescueSigns'),
                value: 'signs/rescue-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.warningSigns'),
                value: 'signs/warning-signs',
              },
              {
                title: t('module-core:shared.common.userDefined'),
                name: t('module-core:shared.common.allOwn'),
                value: '',
              },
              ...fileCategories.map((fileCategory) => ({
                title: t('module-core:shared.common.userDefined'),
                name: fileCategory.name,
                value: fileCategory.name,
                isCustomCategory: true,
                isInEditMode: editMode === fileCategory.name,
                _id: fileCategory._id,
              })),
              ...(!fileCategories.length && fileCategoriesLoading === 'fileCategoryGet'
                ? Array.from({ length: 4 }).map((_, index) => ({
                    title: t('module-core:shared.common.userDefined'),
                    name: String(index),
                    value: String(index),
                    isCustomCategory: true,
                    isInEditMode: false,
                    isSkeleton: true,
                  }))
                : []),
              ...(showExamples
                ? [
                    {
                      title: t('module-core:shared.common.signs'),
                      name: t('module-core:shared.common.examples'),
                      value: 'examples/examples',
                    },
                  ]
                : []),
            ]}
            selected={selectedGroup}
            onNodeSelect={handleSelectGroup}
          />
        </Grid>

        <Grid item xs={9} lg={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={2}>
                  <TextField
                    id="sortBy"
                    name="sortBy"
                    select
                    label={t('module-core:shared.common.sortBy')}
                    onChange={(e) => setSortBy(e.target.value)}
                    value={sortBy}
                    fullWidth
                  >
                    {[
                      { label: t('module-core:shared.common.sortByCreatedAt'), value: 'createdAt' },
                      { label: t('module-core:shared.common.sortByOriginalName'), value: 'originalName' },
                    ].map((option) => (
                      <MenuItem className={classes.selectOption} key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SingleFieldForm
                    loading={loading === 'fileGet'}
                    fieldLabel={t('module-core:shared.common.searchFile')}
                    fieldName="search"
                    handleSubmit={handleSearch}
                    isRequired={false}
                    resetOnSubmit={false}
                    icon={<SearchRounded />}
                    iconColor="primary"
                  />
                </Grid>
                {(!selectedGroup?.value || selectedGroup?.isCustomCategory) && hasFileCreatePermission && (
                  <Grid item xs={3} sm={4}>
                    <Box display="flex" height="100%" justifyContent="flex-end">
                      <Box alignSelf="flex-end">
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <TextWithIconButton component={'label' as any} endIcon={<ImageRounded />}>
                          {t('module-core:shared.common.upload')}
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <input {...getInputProps()} />
                        </TextWithIconButton>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {!isLoading && fileList.length === 0 ? (
                  <Grid item xs={12}>
                    <PlaceholderBox helpText={t('module-core:shared.common.noFiles')} />
                  </Grid>
                ) : isLoading ? (
                  [...Array(3)].map((_, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid key={index} item xs={12} sm={4} md={3}>
                        <Box>
                          <Skeleton variant="rect" height="200px" />
                        </Box>
                        <Box pb={4}>
                          <Skeleton />
                          <Skeleton width="60%" />
                          <Skeleton width="60%" />
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  fileList.map((data) => {
                    if (isFileLoadingState(data)) {
                      return (
                        <Grid item xs={12} sm={4} md={3} key={data.tempId}>
                          <FileUploadCard
                            currentGroup={selectedGroup?.isCustomCategory ? selectedGroup?.value : undefined}
                            fileUpload={data.file}
                            tempId={data.tempId}
                          />
                        </Grid>
                      );
                    }

                    const { file, status } = data;

                    if (isFileType(file)) {
                      return (
                        <Grid key={file._id} item xs={12} sm={4} md={3}>
                          <FileCard
                            _id={file._id}
                            fileName={file.fileName}
                            originalName={file.originalName}
                            createdAt={file.createdAt}
                            createdBy={file.createdBy}
                            src={file.thumbnail?.proxyUrl || ''}
                            status={status}
                            usedBy={file.usedBy}
                            usedByHeader={file.usedByHeader}
                            handleSelect={handleSelect ? () => handleSelect(file) : undefined}
                            hasFileDeletePermission={hasFileDeletePermission}
                            currentGroup={selectedGroup?.value === '' ? file.group : undefined}
                          />
                        </Grid>
                      );
                    }

                    return (
                      <Grid key={file.location} item xs={12} sm={4} md={3}>
                        <FileStaticCard
                          fileName={file.fileName}
                          location={file.location}
                          handleSelect={handleSelect ? () => handleSelect(file) : undefined}
                        />
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
